<template>
  <div class="m-pg-sub">
    <page-cover code="5" layer="rgba(0,0,0,.5)">
      <!-- <h2 class="m-page-title">{{$t('page.result.name')}}</h2>
      <el-breadcrumb class="m-breadcrumb m-box-shadow" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('page.home.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/search' }">{{$t('page.search.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('page.result.name')}}</el-breadcrumb-item>
      </el-breadcrumb> -->
    </page-cover>
    <div class="m-pg-sub_main m-w1200">
      <el-form class="filter" :inline="true">
        <!-- <el-button :loading="exportData.loading" type="primary" @click="onSelectionExport">{{$t('action.exportData')}}</el-button> -->
        <el-button :loading="exportData.loading" type="primary" @click="onExport">{{$t('action.exportData')}}</el-button>
      </el-form>
      <el-table
        class="tb-data el-table--custom"
        v-loading="table.loading"
        :data="table.data"
        size="medium"
        stripe
        tooltip-effect="light"
        @selection-change="onSelectionChange">
        <el-table-column
          fixed="left"
          type="selection"
          header-align="center"
          align="center">
        </el-table-column>

        <!-- 样品号 -->
        <el-table-column
          prop="Sample_Name"
          :label="$t('model.sample.Sample_Name')"
          min-width="180"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 大洲或大洋 -->
        <el-table-column
          prop="Continent_or_Ocean"
          :label="$t('model.sample.Continent_or_Ocean')"
          min-width="180"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 国家 -->
        <el-table-column
          prop="Country"
          min-width="180"
          :label="$t('model.sample.Country')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 地体 -->
        <el-table-column
          prop="Terrane"
          min-width="180"
          :label="$t('model.sample.Terrane')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 地球动力学背景 -->
        <el-table-column
          prop="Geodynamic_settings"
          min-width="180"
          :label="$t('model.sample.Geodynamic_settings')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 岩石或底层单元 -->
        <el-table-column
          prop="Rock_or_stratigraphic_unit"
          min-width="190"
          :label="$t('model.sample.Rock_or_stratigraphic_unit')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 地质时代 -->
        <el-table-column
          prop="Geological_period"
          min-width="180"
          :label="$t('model.sample.Geological_period')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 岩性 -->
        <el-table-column
          prop="Lithology"
          min-width="180"
          :label="$t('model.sample.Lithology')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 变质级别 -->
        <el-table-column
          prop="Metamorphic_grade"
          min-width="180"
          :label="$t('model.sample.Metamorphic_grade')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 测定对象或矿物 -->
        <el-table-column
          prop="Object_or_mineral_determined"
          min-width="214"
          :label="$t('model.sample.Object_or_mineral_determined')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 刊物名 -->
        <el-table-column
          prop="Journal"
          min-width="180"
          :label="$t('model.sample.Journal')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 题名 -->
        <el-table-column
          prop="Title"
          min-width="180"
          :label="$t('model.sample.Title')"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Year"
          min-width="180"
          :label="$t('model.sample.Year')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 第一作者姓名 -->
        <el-table-column
          prop="First_Author"
          min-width="180"
          :label="$t('model.sample.First_Author')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 表达方式 -->
        <el-table-column
          prop="Expression"
          min-width="180"
          :label="$t('model.sample.Expression')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 年龄值(Ma) -->
        <el-table-column
          prop="Age"
          min-width="180"
          :label="$t('model.sample.Age')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 误差2SE(Ma) -->
        <el-table-column
          prop="SE2"
          min-width="180"
          :label="$t('model.sample.SE2')"
          show-overflow-tooltip>
        </el-table-column>

        <!-- 年龄解释 -->
        <el-table-column
          prop="Interpretation"
          min-width="180"
          :label="$t('model.sample.Interpretation')"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          min-width="140"
          :label="$t('word.operation')"
          show-overflow-tooltip>
          <template slot-scope="{ row }">
            <el-button :title="$t('action.detail')" size="mini" type="primary" icon="el-icon-document" @click="onDetail(row)"></el-button>
            <el-button :title="$t('page.result.action.mark')" size="mini" type="primary" icon="el-icon-location" @click="onMark(row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pager"
        background
        layout="prev, pager, next"
        :page-size.sync="page.size"
        :current-page.sync="page.index"
        :total="page.total"
        @current-change="onPageChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import setting from '@/setting'
import dSampleApi from '@/api/dSample'
export default {
  name: 'Search_Result',
  components: {
  },
  data() {
    return {
      searchModel: null,
      table: {
        loading: false,
        data: [],
        selection: []
      },
      page: {
        index: 1,
        size: 20,
        total: 0
      },
      exportData: {
        loading: false,
        downloadUrl: `${setting.baseUrl.api}D_Sample/DownLoadFile`
      }
    }
  },
  methods: {
    getData() {
      this.table.loading = true
      let wheres = this.$route.query.wheres
      let values = this.$route.query.values
      wheres = wheres || '[]'
      dSampleApi.getPageData({
        page: this.page.index,
        rows: this.page.size,
        wheres,
        TableName:'D_Principal_Element1',
        Value: values
      }).then(res => {
        this.table.data = res.rows
        this.page.total = res.total
        this.table.loading = false
      }).catch(err => {
        this.table.loading = false
      })
    },
    onDetail(item) {
      this.$router.push(`/search/result-detail?sampleId=${item.Sample_ID}`)
    },
    onMark(item) {
      window.open(`${setting.visualized.webUrl}?mode=label&sampleID=${item.Sample_ID}`, '_blank')
    },
    onPageChange(val) {
      this.getData()
    },
    onSelectionChange(selection) {
      this.table.selection = selection;
    },
    async onExport() {
      this.exportData.loading = true
      let wheres = this.$route.query.wheres
      wheres = wheres || '[]'
      wheres = JSON.parse(wheres);
      wheres.push({name:"DataType","value":"1,3",displayType:"selectList"})
      if (this.table.selection.length) {
        let ids = this.table.selection.map(item => item.Sample_ID);
        wheres.push({name:"Sample_ID","value":ids.join(','),displayType:"selectList"})
      }
      wheres = JSON.stringify(wheres)
      let { data } = await dSampleApi.exportData({
        wheres
      })
      this.exportData.loading = false
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = `${this.exportData.downloadUrl}?path=${data}`
      document.body.appendChild(link)
      link.click();
      document.body.removeChild(link)
    },
    // 下载选中
    async onSelectionExport() {
      this.exportData.loading = true
      let wheres = [];
      let ids = this.table.selection.map(item => item.Sample_ID);
      wheres.push({name:"Sample_ID","value":ids.join(','),displayType:"selectList"})
      wheres = JSON.stringify(wheres)
      let { data } = await dSampleApi.exportData({
        wheres
      })
      this.exportData.loading = false
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = `${this.exportData.downloadUrl}?path=${data}`
      document.body.appendChild(link)
      link.click();
      document.body.removeChild(link)
    }
  },
  created() {
    if (this.$route.query.searchModel) {
      this.searchModel = JSON.parse(this.$route.query.searchModel)
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
  .m-pg-sub_main {
    margin: 20px auto;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    .filter {
      padding: 12px 16px;
      text-align: right;
      /deep/ .el-input {
        width: 193px;
      }
    }
    .pager {
      padding: 16px 10px;
    }
  }
</style>
